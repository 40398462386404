<template>
  <div v-show="loaded">
    <section
      style="
    height: 100px; padding-top: 20px; text-align: left;"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <h1>{{ env }}Billing Approval</h1>
          </div>
        </div>
      </div>
    </section>

    <div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-sm-12 col-md-8 col-lg-6">
            <div
              class="block tabular"
              style="padding: 20px; position: relative;"
            >
              <div v-if="isError">
                <b-alert variant="warning" show>
                  <strong>Unable to load Approval</strong>
                  <div>
                    {{ errorMessage }}
                  </div>
                </b-alert>
              </div>

              <div v-else-if="isComplete">
                <b-alert variant="success" show>
                  <strong>Rejected</strong>
                  <div>
                    Thank you for letting us know. We have marked this approval
                    to be further reviewed and will be in touch if necessary.
                  </div>
                </b-alert>
              </div>

              <div v-else>
                <p>
                  You are about to reject billing for {{ approval.site_name }}
                </p>

                <p>
                  Please enter your name and reason below and press reject. This
                  will mark the approval for further checks.
                </p>

                <div class="element">
                  <input
                    required
                    v-model="name"
                    type="text"
                    placeholder="Name"
                  />
                </div>

                <div class="element">
                  <input
                    required
                    v-model="reason"
                    type="text"
                    placeholder="Rejection reason"
                  />
                </div>

                <div>
                  <div class="action-button">
                    <b-button
                      v-if="!isSubmitting"
                      @click="reject"
                      variant="outline-danger"
                      >Reject Approval</b-button
                    >
                    <div v-else>
                      <b-spinner variant="success" label="Spinning"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BillingAgentReject",
  props: ["reference"],
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    }
  },
  data() {
    return {
      approval: {},
      name: null,
      reason: null,
      loaded: false,
      isError: false,
      isComplete: false,
      errorMessage: "",
      isSubmitting: false
    };
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  methods: {
    fetchData() {
      const title = "Please wait";
      const subtitle = "Fetching Approval Data";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      const key = this.reference;
      this.$store
        .dispatch("approvalGet", { key })
        .then(response => {
          this.approval = response;
        })
        .catch(err => {
          this.errorMessage = err;
          this.isError = true;
        })
        .finally(() => {
          this.loaded = true;
          this.$store.dispatch("loaderCancel");
        });
    },
    reject() {
      this.isSubmitting = true;

      const key = this.reference;

      const data = {
        id: this.approval.id,
        name: this.name,
        reason: this.reason
      };
      this.$store
        .dispatch("approvalReject", { key, data })
        .then(() => {
          this.isComplete = true;
        })
        .catch(err => {
          const title = "Approval Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 1.8rem;
  text-align: center;
}

.action-button {
  margin-top: 25px;
}
</style>
